<script>
    import Axios from "axios";

    export default {
        name: 'activity-reply-message',
        props: ['activity'],
        data() {
            return {
                show_reply_message: false,
                show_old_body_html: false,
                reply_message: '',
                many_recipients: false,
                new_recipient: '',
                recipients: [],
            };
        },
        computed: {},
        methods: {
            showReplyMessage(many_recipients) {
                if (many_recipients) {
                    this.many_recipients = many_recipients;
                } else {
                    this.many_recipients = false;
                }
                this.show_reply_message = true;
            },
            sendMessage() {
                const data = {
                    activityId: this.activity.id,
                    emailId: this.activity.email.id,
                    message: this.reply_message,
                    recipients: [{email: this.activity.email.from}].concat(this.recipients),
                };
                NProgress.start();
                Axios.post(`/ir/activity/email/reply`, data)
                    .then(response => {
                        if (response.data._status) {
                            this.$emit('loadActivity');
                            this.cancelMessage();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    }).finally(() => {
                    NProgress.done();
                });
            },
            cancelMessage() {
                this.show_reply_message = false;
                this.show_old_body_html = false;
            },
            addNewRecipient() {
                if (this.new_recipient !== '') {
                    this.recipients.push({email: this.new_recipient});
                    this.new_recipient = '';
                }
            },
            removeRecipient(index) {
                this.recipients.splice(index, 1);
            },
        },
        mounted() {

        },
        components: {}
    };
</script>

<template>
    <div class="reply-message" v-if="show_reply_message">
        <p v-if="activity.email.to"><strong>From: </strong>{{activity.email.to}}</p>
        <p v-if="activity.email.from"><strong>To: </strong>{{activity.email.from}}</p>
        <div v-if="many_recipients">
            <p v-for="(to,index) in recipients" :key="to">
                <strong>To: </strong>{{to.email}}
                <button type="button" @click="removeRecipient(index)" class="btn btn-xs">X</button>
            </p>
            <p>
                <strong>To: </strong><input type="text" class="form-control recipient-input" v-model="new_recipient" title="new recipient" placeholder="Add new recipient"/>
                <button type="button" @click="addNewRecipient" class="btn btn-sm w-min-sm waves-effect waves-light btn-primary">+Add</button>
            </p>
        </div>
        <div class="text-message">
            <textarea v-model="reply_message" cols="30" rows="10" placeholder="Type your message here..."></textarea>
        </div>
        <div class="action-btn">
            <span @click="show_old_body_html = !show_old_body_html">...</span>
            <div>
                <button type="button" @click="sendMessage" class="btn w-min-sm waves-effect waves-light btn-primary">Send</button>
                <button type="button" @click="cancelMessage" class="btn w-min-sm waves-effect waves-light">Cancel</button>
            </div>
        </div>
        <div v-if="show_old_body_html" class="card px-1 py-1" v-html="activity.email.stripped_text"></div>
    </div>
</template>
<style>
    .form-control.recipient-input {
        width: 50%;
        display: inline-block;
    }

    .text-message textarea {
        width: 100%;
        resize: none;
        padding: 5px;
    }

    .action-btn {
        display: flex;
        justify-content: space-between;
    }

    .action-btn span {
        cursor: pointer;
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
    }

    .action-btn button {
        margin-right: 4px;
    }
</style>
