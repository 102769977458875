<script>
    /* eslint-disable  */
    import numeral from 'numeral';

    export default {
        name: 'number-formatter',
        data: function () {
            return {
                isFocus: false,
            };
        },
        props: {
            refName:{
                type: String,
                default: ''
            },
            isTotalManual:{
               default:false,
            },
            value: 0,
            percentage: {
                default: false
            },
            format: {
                type: String,
                default: '$0,0'
            },
            isDisabled: {
                type: Boolean,
                default: false
            }
        },
        mounted: function () {
            this.formatValue();
        },
        watch: {
            value: function () {
                if (!this.isFocus) {
                    let vm = this;
                    setTimeout(function () {
                        vm.formatValue();
                    }, 0);
                }
            },
            isDisabled: function () {
                if (!this.isFocus) {
                    let vm = this;
                    setTimeout(function () {
                        vm.formatValue();
                    }, 0);
                }
            }
        },
        computed:{
            computedRef: function(){
                return 'numberFormatter'+this.refName;
            }
        },
        methods: {
            onChange: function () {
               this.$emit('change');
            },
            updateValue: function (value) {
                if (this.isTotalManual && (value == '' || value === null)){
                    var result = null
                }else {
                    var result = value == '0.0' ? '0.0' : value == '.' ? '0.' : numeral(value).format('0.[00]');
                }
                this.$emit('input', result);
            },
            formatValue: function () {
                if (this.percentage) {
                    if ((numeral(this.value).format(this.format)).slice(0, (numeral(this.value).format(this.format)).length - 3) > 100) {
                        this.$refs[this.computedRef].value = 100 + '%';
                    } else {
                        this.$refs[this.computedRef].value = (numeral(this.value).format(this.format)).slice(0, (numeral(this.value).format(this.format)).length - 3) + '%';
                    }
                } else {
                    this.$refs[this.computedRef].value = numeral(this.value).format(this.format);
                    this.$emit('blur');
                }
            },
            selectAll: function (event) {
                this.isFocus = true;
                setTimeout(function () {
                    event.target.select();
                }, 0);
            },

            setValue: function (val) {
                let result = numeral(val).format('0.[00]');
                let formattedResult = numeral(result).format(this.format);
                this.$emit('input', result);
                let vm = this;
                setTimeout(function () {
                    vm.$refs[this.computedRef].value = formattedResult;
                }, 0);
            },
            emitEvent: function (type, event) {
                this.$emit(type, event);
            },
            focus: function () {
                this.isFocus = true;
                this.$refs[this.computedRef].focus();
            },
            select: function () {
                this.isFocus = true;
                this.$refs[this.computedRef].select();
            },
            click: function () {
                this.isFocus = true;
                this.$refs[this.computedRef].click();
            },
            blur: function () {
                this.isFocus = false;
                this.formatValue();
            }
        }
    };

</script>

<template>
    <input :ref="computedRef"
           v-bind:value="value"
           v-on:keydown="emitEvent('onKeyDown', $event)"
           v-on:input="updateValue($event.target.value)"
           v-on:focus="selectAll"
           v-on:blur="blur"
           :disabled="isDisabled"
           @change="onChange()">
</template>





