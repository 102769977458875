import { render, staticRenderFns } from "./activity-item-email.vue?vue&type=template&id=2384cb56&scoped=true"
import script from "./activity-item-email.vue?vue&type=script&lang=js"
export * from "./activity-item-email.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2384cb56",
  null
  
)

export default component.exports