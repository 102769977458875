<script>

    import ActivityItemReplyMessage from './activity-item-reply-message';
    import Axios from "axios";

    export default {
        name: "activity-list-email",
        props: ['email', 'viewed', 'activity', 'index'],
        data: function () {
            return {
                dataEmail: Object.assign({}, this.email),
                a: this.activity,
                isMinimized: !!this.viewed,
                dpOptions: {
                    autoclose: true,
                    todayHighlight: true,
                    format: 'dd/mm/yyyy'
                },
            };
        },
        methods: {
            openMenuEmailAttachments: function (event, item) {
                this.$emit('openMenuForEmailAttachments', event, item);
            },
            markAsReadActivity: function (item) {
                item.viewed = 1;
                Axios.post(`/ir/activity/viewed-status/${item.id}`, { viewed: 1 })
                  .then(response => {
                    console.log(response.data);
                  })
                  .catch(e => console.error(e));
                this.$emit('read', item);
            },
            markAsUnReadActivity: function (item) {
                item.viewed = 0;
                Axios.post(`/ir/activity/viewed-status/${item.id}`, { viewed: 0 })
                    .then(response => {
                      console.log(response.data);
                    })
                    .catch(e => console.error(e));
                this.$emit('unread', item);
            },
            replyActivity(index) {
                const refIndex = 'ActivityItemReplyMessageRef' + index;
                if (this.$refs[refIndex]) {
                    this.$refs[refIndex].showReplyMessage(false);
                }
            },
            replyToAllActivity(index) {
                const refIndex = 'ActivityItemReplyMessageRef' + index;
                if (this.$refs[refIndex]) {
                    this.$refs[refIndex].showReplyMessage(true);
                }
            },
            loadActivity: function () {
                this.$emit('loadActivity');
            },
            markAsUnRead: function (a) {
                this.markAsUnReadActivity(a)
            },
            markAsRead: function (a) {
                this.markAsReadActivity(a)
            },
            destroyMe: function () {
                Vue.$destroy(this);
            }
        },
        computed: {
            computedFiles() {
                if (this.a.email.files.length === 0) {
                    return [];
                }
                return _.filter(this.a.email.files, function (f) {
                    return !(f.isDeleted);
                });
            }
        },
        watch: {},
        components: {
            ActivityItemReplyMessage,
        },
        mounted: function () {

        }
    };
</script>
<template>
    <div class="tl-content box box-block display-block" style="background-color: #FAFAFA;">
        <div class="row d-flex justify-content-between px-1-25 flex-nowrap">
            <div style="flex: 0 0 50%;">
                <div>&nbsp;</div>
            </div>

            <!-- actions -->
            <div :id="`actions-email-${this.email ? this.email.id : 'new'}`" class="dropdown show float-sm-right text-right w-100" style="flex: 0 0 50%;">
                <span class="btn btn-outline-primary w-min-lg b-dd b-act ml-0-25 waves-effect waves-light btnNotBorder"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true">Actions <i class="fa fa-caret-down"></i></span>
                <div class="dropdown-menu dropdown-menu-right act-dd act-dd-right animated  dropdownLinks-container">
                    <div class="dropdownLinks-links">
                        <a @click="replyActivity(index)" class="dropdown-item text-primary p-1 clickable">
                            <i class='bx bx-reply font-16'></i>
                            Reply
                        </a>
                        <a @click="replyToAllActivity(index)" class="dropdown-item text-primary p-1 clickable">
                            <i class='bx bx-reply-all font-16'></i>
                            Reply to All
                        </a>
                        <a v-if="!viewed" @click="markAsRead(activity)" class="dropdown-item text-primary p-1 clickable">
                          <i class="fas fa-eye" style="font-size: 9px; top: -1px; margin-right: 4px;"></i>
                          Mark as Read
                        </a>
                        <a v-if="viewed" @click="markAsUnRead(activity)" class="dropdown-item text-primary p-1 clickable">
                          <i class="fas fa-eye-slash" style="font-size: 9px; top: -1px; margin-right: 4px;"></i>
                          Mark as Unread
                        </a>
                    </div>
                </div>
            </div>

        </div>
        <span class="arrow left" style="border-color: #FAFAFA;"></span>
        <div>
            <activity-item-reply-message
                    :ref="'ActivityItemReplyMessageRef'+index"
                    :activity="a"
                    @loadActivity="loadActivity"

            />
            <p v-if="a.email.from"><strong>From: </strong>{{a.email.from}}</p>
            <p v-if="a.email.recipient"><strong>To: </strong>{{a.email.recipient}}</p>
            <p v-if="a.email.in_out == 'out'"><strong>Sent: </strong>{{a.createdOn | formatDatetime}}</p>
            <p v-else><strong>Received: </strong>{{a.createdOn | formatDatetime}}</p>
            <p v-if="a.email.to"><strong>To: </strong>{{a.email.to}}</p>
            <p v-if="a.email.subject"><strong>Subject: </strong>{{a.email.subject}}</p>
            <p v-if="computedFiles.length">
                <strong>
                    Attachments:
                    <template v-for="(att, index) in computedFiles">
                        <a
                                :key="'file'+att.id"
                                :href="att.url"
                                target="_blank"
                                class="btn btn-primarybtn btn-outline-primary btn-sm tag-pill mr-0-75"
                                style="position:relative;"
                                @contextmenu.prevent="openMenuEmailAttachments($event, att)"
                        >
                            <div style="margin: 5px 23px 5px 5px;" v-if="att.isCopyToClaim || att.isCopyToAssessment">{{att.fileName}}</div>
                            <i class="icon ion-ios-checkmark-circle" style="font-size:20px; color:#2DCA73; position:absolute; top:3px; right:7px;"
                               v-if="att.isCopyToClaim || att.isCopyToAssessment"></i>
                            <div style="margin: 5px" v-else>{{att.fileName}}</div>
                        </a>
                    </template>
                </strong>
            </p>
            <div class="card px-1 py-1" v-html="a.email.html_text"></div>
            <!--            <div class="tl-date text-muted mt-1">{{a.createdOn | formatDatetime}}</div>-->
        </div>
    </div>
</template>

<style scoped>

</style>
